<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4" lg="4">
        <v-img
            :src="require('../assets/logo.png')"
            class="mt-10 mx-auto"
            contain
            height="70"
            max-width="320"
        />
        <h1 class="red--text mb-8 mt-0 text-center">Admin</h1>
        <v-card :loading="loading" @keyup.enter="checkLogin()">
          <v-card-title class="mx-auto">
            <h2 class="mx-auto">connexion</h2>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                  v-model="email"
                  label="Email"
                  outlined
                  prepend-inner-icon="mdi-email"
              ></v-text-field>
              <v-text-field
                  v-model="password"
                  label="Mot de passe"
                  outlined
                  prepend-inner-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-form>
            <v-alert
                v-if="error"
                color="red"
                type="error"
            >{{ error }}</v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn
                depressed
                block
                color="primary"
                @click="checkLogin()"
            >
              Se connecter
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn
                text
                color="primary"
                class="ml-auto"
            >
              Mot de passe oublier
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '../services/API'

import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      showPassword: false,
      email: null,
      password: null,
      loading: false,
      error: null,
    }
  },
  methods: {
    ...mapActions(['saveLogin']),
    checkLogin() {
      this.error = null
      if (this.email && this.password) {
        this.login()
      } else {
        this.error = 'Merci de renseigner tout les champs.'
      }
    },
    login() {
      this.loading = true

      const form = {
        email: this.email,
        password: this.password
      }

      api.user.login(form)
          .then((response) => {
            console.log(response)
            if (response.data) {
              const data = response.data
              if (data.access_token && data.user.name && data.user.email) {
                api.saveAxiosToken(data.access_token)
                this.saveLogin({
                  name: data.user.name,
                  email: data.user.email,
                  token: data.access_token
                })
                this.$router.push('/')
              }
            } else {
              this.error = 'Une erreur serveur est survenue, merci de réessayer ultérieurement.'
            }
            this.loading = false
          })
          .catch(() => {
            this.error = 'Email ou mot de passe incorrect.'
            this.loading = false
          })
    },
  }
}
</script>
